import { Link } from "react-router-dom";
import UserContext from "../context/useContext";
import {useEffect, useState} from "react";

function Sidebar({sideID,parent}){
  const [cooperatives,setCooperatives] = useState([]);
  const user =  UserContext();

    useEffect(()=>{

    },[user,sideID])


    return(
        <nav className="navbar navbar-vertical navbar-expand-lg" >
        <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
          <div className="navbar-vertical-content">
            <ul className="navbar-nav flex-column " id="navbarVerticalNav">
                 <li className="nav-item">
                     {
                         user?.is_adg ?
                             <p className="navbar-vertical-label">BIENVENU - {user?.nom} {user?.prenom}</p> :
                             <p className="navbar-vertical-label">MENU {user?.nom}</p>
                     }
                     <hr className="navbar-vertical-line"/>

                     <div className="nav-item-wrapper">
                        <Link className={sideID == "dash-coop" ? "nav-link label-1 bg-info bg-opacity-25" : "nav-link label-1"} to="/dash-coop/"  role="button" data-bs-toggle="" aria-expanded="false">
                          <div className="d-flex align-items-center"><span className="nav-link-icon"><span data-feather="calendar"></span></span><span className="nav-link-text-wrapper"><span className="nav-link-text">TABLEAU DE BORD</span></span></div>
                          </Link>
                      </div>

                      <div className="nav-item-wrapper"><a className="nav-link dropdown-indicator label-1" href="#nv-CRM" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="nv-CRM">
                          <div className="d-flex align-items-center">
                            <div className="dropdown-indicator-icon"><span className="fas fa-caret-right"></span></div><span className="nav-link-icon"><span data-feather="clipboard"></span></span><span className="nav-link-text">ADMINISTRATION</span>
                          </div>
                        </a>
                        <div className="parent-wrapper label-1">
                            <ul className={parent == "generalite" ? "nav collapse parent show" : "nav collapse parent"}
                                data-bs-parent="#navbarVerticalCollapse" id="nv-CRM">
                                <li className="collapsed-nav-item-title d-none">Paramètres</li>
                                <li className="nav-item">
                                    {user?.is_adg
                                        ?
                                        <Link
                                            className={sideID == 'cooperatives' ? "nav-link bg-info bg-opacity-25 active" : "nav-link"}
                                            to={`/list-coop/`} data-bs-toggle="" aria-expanded="false">
                                            <div className="d-flex align-items-center"><span
                                                className="nav-link-text">Profile</span></div>
                                        </Link>
                                        :
                                        <Link
                                            className={sideID == 'cooperatives' ? "nav-link bg-info bg-opacity-25 active" : "nav-link"}
                                            to={`/list-coop/`} data-bs-toggle="" aria-expanded="false">
                                            <div className="d-flex align-items-center"><span
                                                className="nav-link-text">Coopératives</span></div>
                                        </Link>
                                    }
                                </li>
                                <li className="nav-item">
                                    <Link target='_blank' className="nav-link" to="/carte-parcelles/" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center"><span className="nav-link-text">Géoportail Planting</span></div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                      </div>
                 </li>

                <li className="nav-item">
                    <div className="nav-item-wrapper">
                        <a className="nav-link dropdown-indicator label-1" href="#nv-analyse" role="button"
                           data-bs-toggle="collapse" aria-expanded="true" aria-controls="nv-analyse">
                            <div className="d-flex align-items-center">
                                    <div className="dropdown-indicator-icon">
                                        <span className="fas fa-caret-right"></span>
                                    </div>
                                    <span className="nav-link-icon"><span data-feather="pie-chart"></span>
                                    </span><span className="nav-link-text">ANALYSES RDUE</span>
                                </div>
                            </a>
                            <div className="parent-wrapper label-1">
                                <ul className={parent == "" ? "nav collapse parent show" : "nav collapse parent"}
                                    data-bs-parent="#navbarVerticalCollapse" id="nv-analyse">
                                    <li className="collapsed-nav-item-title d-none">ANALYSES RDUE</li>
                                    {/*<li className="nav-item">*/}
                                    {/*     <Link className={sideID=='' ? "nav-link bg-info bg-opacity-25 active" :"nav-link"} to='/list-campagnes/' data-bs-toggle="" aria-expanded="false">*/}
                                    {/*         <div className="d-flex align-items-center">*/}
                                    {/*           <span className="nav-link-text">Parcelles (Super inf 4ha)</span>*/}
                                    {/*         </div>*/}
                                    {/*     </Link>*/}
                                    {/*</li>*/}
                                    <>
                                        <li className="nav-item">
                                            <Link target='_blank' className="nav-link" to="/carte-coops/" data-bs-toggle="" aria-expanded="false">
                                                <div className="d-flex align-items-center"><span className="nav-link-text">Géoportail</span></div>
                                            </Link>
                                        </li>
                                        {/*<li className="nav-item " >*/}
                                        {/*  <Link className={sideID=='' ? "nav-link bg-info bg-opacity-25 active" :"nav-link"}  href="" data-bs-toggle="" aria-expanded="false">*/}
                                        {/*    <div className="d-flex align-items-center"><span className="nav-link-text">Parcelle (Super sup 4ha)</span></div>*/}
                                        {/*  </Link>*/}
                                        {/*</li>*/}
                                        {/*<li className="nav-item"><a className="nav-link" href="" data-bs-toggle="" aria-expanded="false">*/}
                                        {/*     <div className="d-flex align-items-center"><span className="nav-link-text">Vérification Polygones</span></div>*/}
                                        {/*   </a>*/}
                                        {/* </li>*/}

                                        {/*<li className="nav-item"><a className="nav-link" href="" data-bs-toggle="" aria-expanded="false">*/}
                                        {/*  <div className="d-flex align-items-center"><span className="nav-link-text">Parcelles a Risque élevé</span></div>*/}
                                        {/*    </a>*/}
                                        {/*</li>*/}

                                        {/*<li className="nav-item"><a className="nav-link" href="" data-bs-toggle="" aria-expanded="false">*/}
                                        {/*    <div className="d-flex align-items-center"><span className="nav-link-text">Parcelles à Risque Modéré</span></div>*/}
                                        {/*  </a>*/}
                                        {/*</li>*/}
                                    </>
                                    {/*<li className="nav-item"><a className="nav-link" href="" data-bs-toggle=""*/}
                                    {/*                            aria-expanded="false">*/}
                                    {/*    <div className="d-flex align-items-center"><span*/}
                                    {/*        className="nav-link-text">Agroforesterie</span></div>*/}
                                    {/*</a>*/}
                                    {/*</li>*/}
                                    {/*<li className="nav-item"><a className="nav-link" href="" data-bs-toggle=""*/}
                                    {/*                            aria-expanded="false">*/}
                                    {/*    <div className="d-flex align-items-center"><span*/}
                                    {/*        className="nav-link-text">Reforestation</span></div>*/}
                                    {/*</a>*/}
                                    {/*</li>*/}
                                    {/*<li className="nav-item"><a className="nav-link" href="" data-bs-toggle=""*/}
                                    {/*                            aria-expanded="false">*/}
                                    {/*    <div className="d-flex align-items-center"><span*/}
                                    {/*        className="nav-link-text">Remédiation</span></div>*/}
                                    {/*</a>*/}
                                    {/*</li>*/}
                                    {user && user.id === 8
                                        ?
                                        <li className="nav-item">
                                            <a className="nav-link" target='_blank' href="/analyseAGRIAL" data-bs-toggle="" aria-expanded="false">
                                            <div className="d-flex align-items-center"><span className="nav-link-text">Rapport d'analyse</span></div>
                                            </a>
                                        </li>
                                        :
                                        ""
                                    }
                                    {user && user.id === 11
                                        ?
                                        <li className="nav-item">
                                            <a className="nav-link" href="/analyseCOOPAAHS" data-bs-toggle="" aria-expanded="false">
                                            <div className="d-flex align-items-center"><span className="nav-link-text">Rapport d'analyse</span></div>
                                            </a>
                                        </li>
                                        :
                                        ""
                                    }
                                </ul>
                            </div>
                        </div>
                 </li>

                <li className="nav-item">
                    <div className="nav-item-wrapper">
                        <a className="nav-link dropdown-indicator label-1" href="#nv-home" role="button"
                           data-bs-toggle="collapse" aria-expanded="true" aria-controls="nv-home">
                                <div className="d-flex align-items-center">
                                    <div className="dropdown-indicator-icon">
                                        <span className="fas fa-caret-right"></span>
                                    </div>
                                    <span className="nav-link-icon"><span data-feather="settings"></span>
                                    </span><span className="nav-link-text">PARAMETRES</span>
                                </div>
                            </a>
                            <div className="parent-wrapper label-1">
                                <ul className={parent == "params" ? "nav collapse parent show" : "nav collapse parent"}
                                    data-bs-parent="#navbarVerticalCollapse" id="nv-home">
                                    <li className="collapsed-nav-item-title d-none">Paramètres</li>
                                    <li className="nav-item">
                                        <Link
                                            className={sideID == 'campagne' ? "nav-link bg-info bg-opacity-25 active" : "nav-link"}
                                            to='/list-campagnes/' data-bs-toggle="" aria-expanded="false">
                                            <div className="d-flex align-items-center">
                                                <span className="nav-link-text">Campagnes</span>
                                            </div>
                                        </Link>
                                    </li>
                                    {user?.is_responsable &&
                                        <>
                                            <li className="nav-item ">
                                                <Link
                                                    className={sideID == 'projets' ? "nav-link bg-info bg-opacity-25 active" : "nav-link"}
                                                    to="/list-projets/" data-bs-toggle="" aria-expanded="false">
                                                    <div className="d-flex align-items-center"><span
                                                        className="nav-link-text">Projets</span></div>
                                                </Link>
                                            </li>
                                            {/*  <li className="nav-item"><a className="nav-link" href="apps/social/feed.html" data-bs-toggle="" aria-expanded="false">
                                      <div className="d-flex align-items-center"><span className="nav-link-text">Sections</span></div>
                                    </a>
                                  </li>

                                  <li className="nav-item"><a className="nav-link" href="index.html" data-bs-toggle="" aria-expanded="false">
                                    <div className="d-flex align-items-center"><span className="nav-link-text">Primes</span></div>
                                      </a>
                                  </li>



                                  <li className="nav-item"><a className="nav-link" href="apps/social/feed.html" data-bs-toggle="" aria-expanded="false">
                                      <div className="d-flex align-items-center"><span className="nav-link-text">Sous-Sections</span></div>
                                    </a>
                                  </li>  */}
                                        </>
                                    }
                                    {/*<li className="nav-item">*/}
                                    {/*    <Link*/}
                                    {/*        className={sideID == 'historiques' ? "nav-link bg-info bg-opacity-25 active" : "nav-link"}*/}
                                    {/*        to='/historiques-synchronisation-list/' data-bs-toggle=""*/}
                                    {/*        aria-expanded="false">*/}
                                    {/*        <div className="d-flex align-items-center"><span*/}
                                    {/*            className="nav-link-text">Historiques</span></div>*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    {/*<li className="nav-item">*/}
                                    {/*    <Link className="nav-link" to="/simulation-carbon/" data-bs-toggle=""*/}
                                    {/*          aria-expanded="false">*/}
                                    {/*        <div className="d-flex align-items-center">*/}
                                    {/*            <span className="nav-link-text">Simulateur Carbonne</span>*/}
                                    {/*        </div>*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    {/*<li className="nav-item">*/}

                                    {/*    <p className="navbar-vertical-label">SIMULATEUR</p>*/}
                                    {/*    <hr className="navbar-vertical-line"/>*/}
                                    {/*    <li className="nav-item">*/}
                                    {/*        <Link className="nav-link" to="/simulation-carbon/" data-bs-toggle=""*/}
                                    {/*              aria-expanded="false">*/}
                                    {/*            <div className="d-flex align-items-center">*/}
                                    {/*                <span className="nav-link-text">Carbonne</span>*/}
                                    {/*            </div>*/}
                                    {/*        </Link>*/}
                                    {/*    </li>*/}

                                    {/*</li>*/}
                                    {/*<li className="nav-item"><a className="nav-link" href="" data-bs-toggle="" aria-expanded="false">*/}
                                    {/*     <div className="d-flex align-items-center"><span className="nav-link-text">Sites Pépinière</span></div>*/}
                                    {/*   </a>*/}
                                    {/*</li>*/}
                                    {/* {user?.is_superadmin &&
                                  <>
                                      <li className="nav-item"><a className="nav-link dropdown-indicator" href="#nv-simple1" data-bs-toggle="collapse" aria-expanded="false" aria-controls="nv-simple">
                                  <div className="d-flex align-items-center">
                                    <div className="dropdown-indicator-icon"><span className="fas fa-caret-right"></span></div><span className="nav-link-text">Catégories</span>
                                  </div>
                                </a>
                                <div className="parent-wrapper">
                                  <ul className="nav collapse parent" data-bs-parent="#authentication" id="nv-simple1">
                                    <li className="nav-item"><a className="nav-link" href="pages/authentication/simple/sign-in.html" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center"><span className="nav-link-text">Plants</span></div>
                                      </a>
                                    </li>
                                    <li className="nav-item"><a className="nav-link" href="pages/authentication/simple/sign-in.html" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center"><span className="nav-link-text">Semences</span></div>
                                      </a>
                                    </li>
                                    <li className="nav-item"><a className="nav-link" href="pages/authentication/simple/sign-up.html" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center"><span className="nav-link-text">Projets</span></div>
                                      </a>
                                    </li>
                                   <li className="nav-item"><a className="nav-link" href="pages/authentication/simple/sign-up.html" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center"><span className="nav-link-text">Infrastructures</span></div>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </li>

                              <li className="nav-item"><a className="nav-link" href="apps/social/feed.html" data-bs-toggle="" aria-expanded="false">
                                  <div className="d-flex align-items-center"><span className="nav-link-text">Espèces</span></div>
                                </a>
                              </li>

                               <li className="nav-item"><a className="nav-link" href="apps/social/feed.html" data-bs-toggle="" aria-expanded="false">
                                  <div className="d-flex align-items-center"><span className="nav-link-text">Pays</span></div>
                                </a>
                              </li>

                              <li className="nav-item"><a className="nav-link" href="apps/social/feed.html" data-bs-toggle="" aria-expanded="false">
                                  <div className="d-flex align-items-center"><span className="nav-link-text">Régions</span></div>
                                </a>
                              </li>

                              <li className="nav-item"><a className="nav-link" href="apps/social/feed.html" data-bs-toggle="" aria-expanded="false">
                                  <div className="d-flex align-items-center"><span className="nav-link-text">Localités</span></div>
                                </a>
                              </li>
                                  </>
                              } */}
                                    {/* <li className="nav-item">
                                  <a className="nav-link dropdown-indicator" href="#nv-simple" data-bs-toggle="collapse" aria-expanded="false" aria-controls="nv-simple">
                                    <div className="d-flex align-items-center">
                                      <div className="dropdown-indicator-icon"><span className="fas fa-caret-right"></span></div><span className="nav-link-text">Utilisateurs</span>
                                    </div>
                                        </a>
                                        <div className="parent-wrapper">
                                          <ul className="nav collapse parent" data-bs-parent="#authentication" id="nv-simple">
                                            {user?.is_responsable &&
                                              <>
                                                    <li className="nav-item">
                                                        <a className="nav-link" href="/users" data-bs-toggle="" aria-expanded="false">
                                                        <div className="d-flex align-items-center">
                                                            <span className="nav-link-text">Liste Utilisateurs</span>
                                                        </div>
                                                      </a>
                                                    </li>
                                              </>
                                            }

                                            {user?.is_superadmin &&
                                              <>
                                                <li className="nav-item"><a className="nav-link" href="pages/authentication/simple/sign-in.html" data-bs-toggle="" aria-expanded="false">
                                                    <div className="d-flex align-items-center"><span className="nav-link-text">Rôles</span></div>
                                                  </a>
                                                </li>
                                                <li className="nav-item"><a className="nav-link" href="pages/authentication/simple/sign-up.html" data-bs-toggle="" aria-expanded="false">
                                                    <div className="d-flex align-items-center"><span className="nav-link-text">Permissions</span></div>
                                                  </a>
                                                </li>
                                              </>
                                            }


                                          </ul>
                                        </div>
                                </li>  */}
                                </ul>
                            </div>
                    </div>
                </li>


                {/*<li className="nav-item">*/}
                {/*       <div className="nav-item-wrapper">*/}
                {/*           <a className="nav-link dropdown-indicator label-1" href="#nv-carbone" role="button" data-bs-toggle="collapse" aria-expanded="true" aria-controls="nv-carbone">*/}
                {/*               <div className="d-flex align-items-center">*/}
                {/*                   <div className="dropdown-indicator-icon">*/}
                {/*                       <span className="fas fa-caret-right"></span>*/}
                {/*                   </div>*/}
                 {/*                   <span className="nav-link-icon"><span data-feather="pie-chart"></span>*/}
                 {/*                   </span><span className="nav-link-text">SIMILATEUR</span>*/}
                 {/*               </div>*/}
                 {/*           </a>*/}
                 {/*           <div className="parent-wrapper label-1">*/}
                 {/*           <ul className={parent == "" ? "nav collapse parent show" :"nav collapse parent"} data-bs-parent="#navbarVerticalCollapse" id="nv-carbone">*/}
                 {/*             <li className="collapsed-nav-item-title d-none">SIMILATEUR</li>*/}
                 {/*                <li className="nav-item">*/}
                 {/*                    <a className="nav-link" href="" data-bs-toggle="" aria-expanded="false">*/}
                 {/*                     <div className="d-flex align-items-center"><span className="nav-link-text">Carbone</span></div>*/}
                 {/*                   </a>*/}
                 {/*                </li>*/}
                 {/*           </ul>*/}
                 {/*         </div>*/}
                 {/*       </div>*/}
                 {/*   </li>*/}
            {/*   <li className="nav-item">
                
                <p className="navbar-vertical-label">CLIENTS</p>
                <hr className="navbar-vertical-line" />
       
                <div className="nav-item-wrapper"><a className="nav-link label-1" href="apps/calendar.html" role="button" data-bs-toggle="" aria-expanded="false">
                    <div className="d-flex align-items-center"><span className="nav-link-icon"><span data-feather="calendar"></span></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Dashbord</span></span></div>
                    </a>
                </div>

                <div className="nav-item-wrapper"><a className="nav-link dropdown-indicator label-1" href="#nv-CRM1" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="nv-CRM">
                    <div className="d-flex align-items-center">
                      <div className="dropdown-indicator-icon"><span className="fas fa-caret-right"></span></div><span className="nav-link-icon"><span data-feather="calendar"></span></span><span className="nav-link-text">Généralités</span>
                    </div>
                  </a>
                  <div className="parent-wrapper label-1">
                    <ul className="nav collapse parent" data-bs-parent="#navbarVerticalCollapse" id="nv-CRM1">
                      <li className="collapsed-nav-item-title d-none">Généralités</li>
                      <li className="nav-item"><a className="nav-link" href="apps/crm/analytics.html" data-bs-toggle="" aria-expanded="false">
                          <div className="d-flex align-items-center"><span className="nav-link-text">Gestion producteurs</span></div>
                        </a>
                      </li>
                      <li className="nav-item"><a className="nav-link" href="apps/crm/deals.html" data-bs-toggle="" aria-expanded="false">
                          <div className="d-flex align-items-center"><span className="nav-link-text">Gestion Parcelles</span></div>
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="nav-item-wrapper"><a className="nav-link dropdown-indicator label-1" href="#nv-project-management1" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="nv-project-management">
                    <div className="d-flex align-items-center">
                      <div className="dropdown-indicator-icon"><span className="fas fa-caret-right"></span></div><span className="nav-link-icon"><span data-feather="clipboard"></span></span><span className="nav-link-text">Cartographies</span>
                    </div>
                  </a>
                  <div className="parent-wrapper label-1">
                    <ul className="nav collapse parent" data-bs-parent="#navbarVerticalCollapse" id="nv-project-management1">
                      <li className="collapsed-nav-item-title d-none">Cartographies</li>
                     
                      <li className="nav-item"><a className="nav-link" href="apps/project-management/project-list-view.html" data-bs-toggle="" aria-expanded="false">
                          <div className="d-flex align-items-center"><span className="nav-link-text">Carte simple</span></div>
                        </a>
                      </li>
                      <li className="nav-item"><a className="nav-link" href="apps/project-management/project-card-view.html" data-bs-toggle="" aria-expanded="false">
                          <div className="d-flex align-items-center"><span className="nav-link-text">Carte contours</span></div>
                        </a>
                      </li>

                      <li className="nav-item"><a className="nav-link" href="apps/project-management/project-card-view.html" data-bs-toggle="" aria-expanded="false">
                          <div className="d-flex align-items-center"><span className="nav-link-text">Carte pépinières</span></div>
                        </a>
                      </li>
                    
                    </ul>
                  </div> 
                </div>
                <div className="nav-item-wrapper"><a className="nav-link label-1" href="apps/chat.html" role="button" data-bs-toggle="" aria-expanded="false">
                    <div className="d-flex align-items-center"><span className="nav-link-icon"><span data-feather="message-square"></span></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Statistiques</span></span></div>
                  </a></div>
            
              </li>
              <li className="nav-item">
                
                <p className="navbar-vertical-label">PEPINIERES</p>
                <hr className="navbar-vertical-line" />
                <div className="nav-item-wrapper"><a className="nav-link label-1" href="pages/starter.html" role="button" data-bs-toggle="" aria-expanded="false">
                    <div className="d-flex align-items-center"><span className="nav-link-icon"><span data-feather="compass"></span></span><span className="nav-link-text-wrapper"><span className="nav-link-text">Dashbord</span></span></div>
                  </a></div>
                <div className="nav-item-wrapper"><a className="nav-link dropdown-indicator label-1" href="#nv-faq" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="nv-faq">
                    <div className="d-flex align-items-center">
                      <div className="dropdown-indicator-icon"><span className="fas fa-caret-right"></span></div><span className="nav-link-icon"><span data-feather="help-circle"></span></span><span className="nav-link-text">Gestions des sites</span>
                    </div>
                  </a>
                  <div className="parent-wrapper label-1">
                    <ul className="nav collapse parent" data-bs-parent="#navbarVerticalCollapse" id="nv-faq">
                      <li className="collapsed-nav-item-title d-none">Gestions</li>
                      <li className="nav-item"><a className="nav-link" href="pages/faq/faq-accordion.html" data-bs-toggle="" aria-expanded="false">
                          <div className="d-flex align-items-center"><span className="nav-link-text">Sites</span></div>
                        </a>
                      </li>
                      <li className="nav-item"><a className="nav-link" href="pages/faq/faq-tab.html" data-bs-toggle="" aria-expanded="false">
                          <div className="d-flex align-items-center"><span className="nav-link-text">Comptes rendu</span></div>
                        </a>
                      </li>

                      <li className="nav-item"><a className="nav-link" href="pages/faq/faq-tab.html" data-bs-toggle="" aria-expanded="false">
                          <div className="d-flex align-items-center"><span className="nav-link-text">Carte des sites</span></div>
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>
            
              </li>
              <li className="nav-item">
                
                <p className="navbar-vertical-label">ENQUETES SOCIALES</p>
                <hr className="navbar-vertical-line" />
                <li className="nav-item"><a className="nav-link" href="apps/social/feed.html" data-bs-toggle="" aria-expanded="false">
                      <div className="d-flex align-items-center"><span className="nav-link-text">Productions</span></div>
                    </a>
                </li>

                <li className="nav-item"><a className="nav-link" href="apps/social/feed.html" data-bs-toggle="" aria-expanded="false">
                      <div className="d-flex align-items-center"><span className="nav-link-text">Familles</span></div>
                    </a>
                </li>

                <li className="nav-item"><a className="nav-link" href="apps/social/feed.html" data-bs-toggle="" aria-expanded="false">
                      <div className="d-flex align-items-center"><span className="nav-link-text">Infrastructures</span></div>
                    </a>
                </li>
               
              </li>*/}

           
            </ul>
          </div>
        </div>
        {/* <div className="navbar-vertical-footer"><button className="btn navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center"><span className="uil uil-left-arrow-to-left fs-0"></span><span className="uil uil-arrow-from-right fs-0"></span><span className="navbar-vertical-footer-text ms-2">Collapsed View</span></button></div> */}
      </nav>
    )
}

export default Sidebar;