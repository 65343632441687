let mapLink= '<a href="http://www.esri.com/">Esri</a>';
let wholink= 'i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community';

export default {
    maptiler: {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    },
    googleMap: {
        // let instagram = 'Instagram';
        // let twitter = 'Twitter';
        // let facebook = 'Facebook';
        // let result = 'I am available on ' + instagram + ', ' + twitter + ' and ' + facebook;
        // console.log(result);
        minZoom: 7,
        maxZoom: 22,
        attribution: '&copy; '+mapLink+', '+ wholink,
        url: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
    }
}