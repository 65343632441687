import Content from "../../../Content";

function ProducteurList(){
    return (
        <>
            <Content sideID={"cooperatives"} parent={"generalite"}>
                <h1>LISTE DES PRODUCTEURS</h1>
            </Content>
        </>
    )
}

export default ProducteurList;