import { useEffect, useState } from "react";
import Content from "../../Content";
import UserContext from "../../context/useContext";
import Chart from 'react-apexcharts';
import axios from "axios";
import {Link} from "react-router-dom";

import BaseUrl from "../../config/baseUrl";

// const baseUrl = 'http://127.0.0.1:8000/api';
const url = BaseUrl();
function DashCoop(){
    const user = UserContext();
    const [cooperative,setCooperative] = useState([]);
    const [sectionProd,setSectionProd] = useState([]);
    const [sectionStateData,SetSectionStateData] = useState({series: [],labels: []})
    const [risqueStateData,SetRisqueStateData] = useState({series: [],labels: []})

    const [firstChart,setFirstChart] = useState({
      options: { 
        chart: {
          id: "basic-bar"
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        }
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 45, 50, 49, 60, 70, 91]
        },
        {
          name: "series-2",
          data: [3, 50, 405, 10, 19, 80, 30, 21]
        },
        //on peut creer plusieur series
      ]
    });



    useEffect(()=>{
      if(user && user?.id){
          console.log(user.id)
        try {
          axios.get(url+'/cooperative-list/?userID='+user.id).then((resp)=>{
            setCooperative(resp.data[0]);
            console.log(resp.data[0]);

            axios.get(url+'/producteurs-by-section/?cooperative='+resp.data[0]?.id).then((response)=>{
              const states = Object.keys(response.data.dataSection).map(key=> response.data.dataSection[key]);
              // console.log(states)
               SetSectionStateData(
                {
                  series: Object.values(response.data.dataSection),
                  labels: states.map((lab)=>lab.libelle)
                }
              ) 

            });

            // Parcelles Par Risque
            axios.get(url+'/parcelle_by_risque/?cooperative='+resp.data[0]?.id).then((response)=>{
              const states2 = Object.keys(response.data.dataRisque).map(key=> response.data.dataRisque[key]);
              console.log(states2)
               SetRisqueStateData(
                {
                  series: Object.values(response.data.dataRisque),
                  labels: states2.map((lab)=>lab.libelle)
                }
              )

            });

          });
        } catch (error) {
          console.log(error);
        }
      }
    },[user]);

    const options3 = {
      colors: ['#008000'],
      // colors: ['#FF9800'],
      xaxis: {
          categories: sectionStateData.labels
      }
    }

    const optionsPlantingEspèce = {
      colors: ['#008000'],
      // colors: ['#FF9800'],
      xaxis: {
          categories: [
              "ACAJOU",
              "CEDRELA",
              "BETE",
              "FRAKE",
              "FRAMIRE",
              "PETIT COLA",
          ]
      }
    }

     const seriePlantingEspèce = [
      {
          colors: ['#ffffff'],
          name: "Espèce",
          data: [0, 0, 0, 0, 0 , 0]
      },
    ]

    const serie2 = [
      {
          colors: ['#ffffff'],
          name: "Section",
          data: sectionStateData.series.map((serie)=>serie.prod_num)
      },
    ]

    const seriePlantingSection = [
      {
          colors: ['#ffffff'],
          name: "Section",
          data: [0, 0, 0, 0, 0 , 0]
      },
    ]

// const serieParcelle = [
//
//   {
//       colors: ['#ffffff'],
//       name: "Section",
//       data: sectionStateData.series.map((serie)=>serie.prod_num)
//   },
// ]

    const seriesPie= [{
        name: "Risque",
        data: risqueStateData.series.map((serie)=>serie.parcelle_num)
    }]

    const optionsPie= {
      colors: ['#93C3EE', '#E5C6A0', '#669DB5', '#94A74A'],
      chart: {
        width: "100%",
        type: 'pie',
      },
      labels: [risqueStateData.labels],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }

    const options4 = {
      // colors: ['#008000'],
      // colors: ['#FF9800'],
      //   colors: ['#93C3EE', '#E5C6A0', '#669DB5', '#94A74A'],
        colors: ['#696969'],
        labels: risqueStateData.labels
      // xaxis: {
      //     categories: risqueStateData.labels
      // }
    }
    const labels = risqueStateData.labels
    // const responsive: {
    //     breakpoint: "480",
    //     options: {
    //       chart: {
    //         width: "200",
    //       },
    //       legend: {
    //         position: 'bottom'
    //       }
    //     }
    // }
    const serie4 = [
      {
          colors: ['#ffffff'],
          name: "Risque",
          data: risqueStateData.series.map((serie)=>serie.parcelle_num)
      },
    ]

    // series: [44, 55, 13, 43, 22],
    // options: {
    //   chart: {
    //     width: 380,
    //     type: 'pie',
    //   },
    //   labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
    //   responsive: [{
    //     breakpoint: 480,
    //     options: {
    //       chart: {
    //         width: 200
    //       },
    //       legend: {
    //         position: 'bottom'
    //       }
    //     }
    //   }]
    // },

    //console.log(sectionStateData);


    return (
        <Content sideID={"dash-coop"} parent={""}>
          <h2>TABLEAU DE BORD  | {user?.nom} {user?.prenom}</h2>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white pt-3 pb-2 border-top border-300">
          
        <div className="mb-5 bg-opacity-50 p-3 border-2 rounded-2 " >
        {user &&
          <>
            <div className="row row-cols-5">
                {/*<div className="col-md-2">*/}
                {/*    /!*  <div className="card">*/}
                {/*        <h5 className="card-header bg-primary text-white p-2 text-center">*/}
                {/*            Mes projets*/}
                {/*        </h5>*/}
                {/*        <div className="card-body p-2">*/}
                {/*            <h3 className="card-title text-center text-secondary">1</h3>*/}
                {/*        </div>*/}
                {/*    </div> *!/*/}
                {/*</div>*/}
                <div className="col col-xs-5">
                    <div className="card">
                        <h5 className="card-header bg-info text-white p-2 text-center">
                             Producteurs
                        </h5>
                        <div className="card-body p-2">
                            <div className="row">
                                <div className="col-xs-3">
                                    <i className="fa-solid fa-users text-primary fa-2x"></i>
                                </div>
                                <div className="col-xs-9">
                                     { cooperative.total_producteurs_coop
                                      ? <h2 className="card-title text-end text-primary" style={{marginTop: "-35px", fontWeight: "900"}}>
                                             <Link style={{color: "#0097EB"}}  to={`/coops/producteur-list/${cooperative.id}`}>{cooperative.total_producteurs_coop}</Link>
                                     </h2>
                                      : <h2 className="card-title text-end text-primary" style={{marginTop: "-35px", fontWeight: "900"}}>0</h2>
                                     }
                                    {/*<h3 className="card-title text-end text-warning" ></h3>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col col-xs-5">
                    <div className="card">
                        <h5 className="card-header bg-teal text-white p-2 text-center" style={{background: "teal"}}>
                            Parcelles
                        </h5>
                        <div className="card-body p-2">
                            <div className="row">
                                <div className="col-xs-3">
                                    <i className="fa-solid text-teal fa-2x fa-location-dot" style={{color: "teal"}}></i>
                                </div>
                                <div className="col-xs-9">
                                    { cooperative.total_parcelles_coop
                                      ?
                                        <h2 className="card-title text-end text-teal" style={{marginTop: "-35px", fontWeight: "900", color: "teal"}}>
                                            <Link style={{color: "teal"}}  to={`/coops/parcelles-list/${cooperative.id}`}>{cooperative.total_parcelles_coop}</Link>
                                            {/*{cooperative.total_parcelles_coop}*/}
                                        </h2>
                                      : <h2 className="card-title text-end text-teal" style={{marginTop: "-35px", fontWeight: "900", color: "teal"}}>0</h2>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col col-xs-5">
                    <div className="card">
                        <h5 className="card-header bg-warning text-white p-2 text-center">
                            Superficie (Ha)
                        </h5>
                        <div className="card-body p-2">
                            {/*<h3 className="card-title text-center text-warning"></h3>*/}
                            <div className="row">
                                <div className="col-xs-3">
                                    <i className="fa-solid text-warning fa-2x fa-map-location-dot"></i>
                                </div>
                                <div className="col-xs-9">
                                    {cooperative.sumSuperficie?.total > 0
                                        ? <h2 className="card-title text-end text-warning" style={{marginTop: "-35px", fontWeight: "900"}}>
                                            {cooperative.sumSuperficie?.total.toFixed(2)}
                                        </h2>
                                        : <h2 className="card-title text-end text-warning" style={{marginTop: "-35px", fontWeight: "900"}}>0</h2>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col col-xs-5">
                    <div className="card">
                        <h5 className="card-header bg-success text-white p-2 text-center">
                            Arbres plantés
                        </h5>
                        <div className="card-body p-2">
                            <div className="row">
                                <div className="col-xs-3">
                                    <i className="fa-solid text-success fa-2x fa-tree"></i>
                                </div>
                                <div className="col-xs-9">
                                    {cooperative.sumPlantCoop?.total > 0
                                        ? <h2 className="card-title text-end text-success" style={{marginTop: "-35px", fontWeight: "900"}}>{cooperative.sumPlantCoop?.total}</h2>
                                        : <h2 className="card-title text-end text-success" style={{marginTop: "-35px", fontWeight: "900"}}>0</h2>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col col-xs-5">
                <div className="card">
                    <h5 className="card-header bg-dark text-white p-2 text-center">
                        {/*Carbone eq CO2 (tonne)*/}
                        CO2 séquestré eq (T)
                    </h5>
                    <div className="card-body p-2">
                        <div className="row">
                            <div className="col-xs-3">
                                <i className="fa-solid text-dark fa-2x fa-smog"></i>
                            </div>
                            <div className="col-xs-9">
                                {/*<h3 className= "card-title text-center text-info-500" >{cooperative.carbonStockeCoop}</h3>*/}
                                {cooperative.carbonStockeCoop > 0
                                    ? <h2 className="card-title text-end text-dark" style={{marginTop: "-35px", fontWeight: "900"}}>{cooperative.carbonStockeCoop.toFixed(2)}</h2>
                                    : <h2 className="card-title text-end text-dark" style={{marginTop: "-35px", fontWeight: "900"}}>0</h2>
                                }
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            {/*<hr/>*/}
              <div className="row mt-3">
                  <div className="col col-xs-5">
                      <div className="card">
                          <h5 className="card-header bg-info-300 text-white p-2 text-center">
                              Formations
                          </h5>
                          <div className="card-body p-2">
                              <div className="rox">
                                  <div className="col-xs-3">
                                      <i className="fa-solid text-info-300 fa-2x fa-list"></i>
                                  </div>
                                  <div className="col-xs-9">
                                      <h2 className="card-title text-end text-info-300"
                                          style={{marginTop: "-35px", fontWeight: "900"}}>0</h2>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className="col col-xs-5">
                      <div className="card">
                          <h5 className="card-header text-white p-2 text-center" style={{backgroundColor: "#559a81"}}>
                              Production totale
                          </h5>
                          <div className="card-body p-2">
                              <div className="row">
                                  <div className="col-xs-3">
                                      <i className="fa-sharp fa-solid fa-2x fa-cubes" style={{color: "#559a81"}}></i>
                                  </div>
                                  <div className="col-xs-9">
                                      <h2 className="card-title text-end"
                                          style={{marginTop: "-35px", fontWeight: "900", color: "#559a81"}}>
                                          0
                                      </h2>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col col-xs-5">
                      <div className="card">
                          <h5 className="card-header bg-warning-600 text-white p-2 text-center">
                              Nbre Parcelles (aire inf à 4Ha)
                          </h5>
                          <div className="card-body p-2">
                              {/*<h3 className="card-title text-center text-warning"></h3>*/}
                              <div className="row">
                                  <div className="col-xs-3">
                                      <i className="fa-solid text-warning-600 fa-cubes-stacked fa-2x"></i>
                                  </div>

                                  <div className="col-xs-9">
                                      {cooperative.total_parcelles_inf_4ha
                                          ?
                                          <h2 className="card-title text-end text-warning-600" style={{ marginTop: "-35px", fontWeight: "900"}}>
                                              <Link style={{color: "#BC3803"}}  to={`/coops/parcelles-list-inf-4ha/${cooperative.id}`}>{cooperative.total_parcelles_inf_4ha}</Link>
                                          </h2>
                                          :
                                          <h2 className="card-title text-end text-warning-600" style={{marginTop: "-35px", fontWeight: "900"}}>0</h2>
                                      }
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col col-xs-5">
                      <div className="card">
                          <h5 className="card-header bg-success-600 text-white p-2 text-center">
                              Nbre parcelles (aire sup à 4Ha)
                          </h5>
                          <div className="card-body p-2">
                              <div className="row">
                                  <div className="col-xs-3">
                                      <i className="fa-solid text-success-600 fa-cube fa-2x"></i>
                                  </div>
                                  <div className="col-xs-9">
                                      {cooperative.total_parcelles_sup_4ha
                                          ? <h2 className="card-title text-end text-success-600" style={{marginTop: "-35px", fontWeight: "900"}}>
                                              <Link style={{color: "#1C6C09"}}  to={`/coops/parcelles-list-sup-4ha/${cooperative.id}`}>{cooperative.total_parcelles_sup_4ha}</Link>
                                            </h2>
                                          : <h2 className="card-title text-end text-success-600" style={{marginTop: "-35px", fontWeight: "900"}}>0</h2>
                                      }
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className="col col-xs-5">
                      <div className="card">
                          {cooperative.total_parcelle_coop_risque_eleve > cooperative.total_parcelle_coop_risque_modere
                              ? <h5 className="card-header bg-danger text-white p-2 text-center">
                                  Parcelles à Risque
                              </h5>
                              :
                              <h5 className="card-header bg-danger text-white p-2 text-center">
                                  Parcelles à Risques
                              </h5>
                          }
                          <div className="card-body p-2">
                              <div className="rox">
                                  <div className="col-xs-3">
                                      {/*<i className="fa-solid text-danger-300 fa-3x fa-list"></i>*/}
                                      <i className="fa-solid text-danger-300 fa-2x fa-triangle-exclamation"></i>
                                  </div>
                                  <div className="col-xs-9">
                                      {/*{cooperative.total_parcelles_coop_risk_modere >= 0 && cooperative.total_parcelles_coop_risk_eleve >= 0*/}
                                      {/*    ? <h2 className="card-title text-end text-danger-300" style={{*/}
                                      {/*        marginTop: "-35px",*/}
                                      {/*        fontWeight: "900"*/}
                                      {/*    }}>{cooperative.total_parcelles_coop_risk_modere + cooperative.total_parcelles_coop_risk_eleve}</h2>*/}
                                      {/*    : <h2 className="card-title text-end text-danger-300"*/}
                                      {/*          style={{marginTop: "-35px", fontWeight: "900"}}>0</h2>*/}
                                      {/*}*/}
                                      <h2 className="card-title text-end text-danger-300" style={{marginTop: "-35px", fontWeight: "900"}}>6</h2>
                                      {/*<h2 className="card-title text-end text-danger-300" style={{marginTop: "-35px", fontWeight: "900"}}>0</h2>*/}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  {/*  <div className="col-md-2">*/}
                  {/*      <div className="card">*/}
                  {/*          <h5 className="card-header bg-success text-white p-2 text-center">*/}
                  {/*              Arbres plantés*/}
                  {/*          </h5>*/}
                  {/*          <div className="card-body p-2">*/}
                  {/*              <div className="row">*/}
                  {/*                  <div className="col-xs-3">*/}
                  {/*                      <i className="fa-solid text-success fa-3x fa-tree"></i>*/}
                  {/*                  </div>*/}
                  {/*                  <div className="col-xs-9">*/}
                  {/*                      {cooperative.sumPlantCoop?.total > 0*/}
                  {/*                          ? <h2 className="card-title text-end text-success" style={{marginTop: "-35px", fontWeight: "900"}}>{cooperative.sumPlantCoop?.total}</h2>*/}
                  {/*                          : <h2 className="card-title text-end text-success" style={{marginTop: "-35px", fontWeight: "900"}}>0</h2>*/}
                  {/*                      }*/}
                  {/*                  </div>*/}
                  {/*              </div>*/}
                  {/*          </div>*/}
                  {/*      </div>*/}
                  {/*  </div>*/}
                  {/*  <div className="col-md-2">*/}
                  {/*  <div className="card">*/}
                  {/*      <h5 className="card-header bg-info-500 text-white p-2 text-center">*/}
                  {/*          /!*Carbone eq CO2 (tonne)*!/*/}
                  {/*          CO2 Séquestré eq (T)*/}
                  {/*      </h5>*/}
                  {/*      <div className="card-body p-2">*/}
                  {/*          <div className="row">*/}
                  {/*              <div className="col-xs-3">*/}
                  {/*                  <i className="fa-solid text-info-500 fa-3x fa-smog"></i>*/}
                  {/*              </div>*/}
                  {/*              <div className="col-xs-9">*/}
                  {/*                  /!*<h3 className= "card-title text-center text-info-500" >{cooperative.carbonStockeCoop}</h3>*!/*/}
                  {/*                  {cooperative.carbonStockeCoop > 0*/}
                  {/*                      ? <h2 className="card-title text-end text-success" style={{marginTop: "-35px", fontWeight: "900"}}>{cooperative.carbonStockeCoop}</h2>*/}
                  {/*                      : <h2 className="card-title text-end text-success" style={{marginTop: "-35px", fontWeight: "900"}}>0</h2>*/}
                  {/*                  }*/}
                  {/*              </div>*/}
                  {/*          </div>*/}
                  {/*      </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
              </div>
              <hr/>
              <div className="row">

                  <div className="col-md-6 col-xs-12">
                      <div className="card text-center mb-3">
                          <div className="card-header bg-info">
                              <h4 className="text-center text-white">Producteurs / sections</h4>
                          </div>
                          <div className="card-body">
                              {/*<h5 className="text-center ">Producteurs par section</h5>*/}
                              <Chart
                                  options={options3}
                                  series={serie2}
                                  type="bar"
                                  width="100%"
                              />
                          </div>
                      </div>
                  </div>

                  <div className="col-6">
                      <div className="card text-center mb-3">
                          <div className="card-header bg-danger">
                              <h4 className="text-center text-white">Parcelles / Risques</h4>
                          </div>
                          <div className="card-body" id="chart">
                              <Chart
                                  options={options4}
                                  series={serie4}
                                  type="bar"
                                  width="100%"
                              />
                          </div>
                      </div>
                  </div>

                  {/*<div className="col-6">*/}
                  {/*<div className="col-md-6 col-xs-12">*/}
                  {/*    <div className="card text-center mb-3">*/}
                  {/*        <div className="card-header bg-black">*/}
                  {/*            <h4 className="text-center text-white">Parcelles par section</h4>*/}
                  {/*        </div>*/}
                  {/*        <div className="card-body">*/}
                  {/*            /!*<h5 className="text-center ">Producteurs par section</h5>*!/*/}
                  {/*            <Chart*/}
                  {/*                options={options3}*/}
                  {/*                series={serie2}*/}
                  {/*                type="bar"*/}
                  {/*                width="100%"*/}
                  {/*            />*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*</div>*/}


                  <div className="col-6">
                      <div className="card text-center mb-3">
                          <div className="card-header bg-info">
                              <h4 className="text-center text-white">Plantings / Sections</h4>
                          </div>
                          <div className="card-body">
                                {/*<h5 className="text-center ">Producteurs par section</h5>*/}
                                <Chart
                                  options={options3}
                                  series={seriePlantingSection}
                                  type="bar"
                                  width="100%"
                                />
                          </div>
                      </div>
                  </div>

                  <div className="col-6">
                      <div className="card text-center mb-3">
                          <div className="card-header bg-info">
                              <h4 className="text-center text-white">Plantings / Espèces</h4>
                          </div>
                          <div className="card-body">
                                {/*<h5 className="text-center ">Producteurs par section</h5>*/}
                                <Chart
                                  options={optionsPlantingEspèce}
                                  series={seriePlantingEspèce}
                                  type="bar"
                                  width="100%"
                                />
                          </div>
                      </div>
                  </div>

                  {/*<div className="col-6">*/}
                  {/*    <div className="card text-center mb-3">*/}
                  {/*        <div className="card-header bg-success">*/}
                  {/*            <h4 className="text-center text-white">Production par Campagne</h4>*/}
                  {/*        </div>*/}
                  {/*        <div className="card-body">*/}
                  {/*              /!*<h5 className="text-center ">Producteurs par section</h5>*!/*/}
                  {/*              <Chart*/}
                  {/*                options={options3}*/}
                  {/*                series={serie2}*/}
                  {/*                type="bar"*/}
                  {/*                width="100%"*/}
                  {/*              />*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*</div>*/}

                  {/*<div className="col-6">*/}
                  {/*<h3 className="text-center">Titre de chart bar2</h3>*/}
                  {/*  <Chart*/}
                  {/*    options={firstChart.options}*/}
                  {/*    series={firstChart.series}*/}
                  {/*    type="treemap"*/}
                  {/*    width="100%"*/}
                  {/*  />*/}
                  {/*</div>*/}

              </div>
          </>
        }


            {/*  <div className="row">
            <div className="col-6">
                <h3 className="text-center text-white">Titre de chart bar4</h3>
                  <Chart
                    options={firstChart.options}
                    series={firstChart.series}
                    type="radar"
                    width="100%"
                  />
                </div>

                <div className="col-6">
                <h3 className="text-center text-white">Titre de chart bar5</h3>
                   <Chart
                    options={firstChart.options}
                    series={firstChart.series}
                    type="scatter"
                    width="100%"
                  /> 
                </div>

            </div> */}
        </div>
        </div>
        </Content>

    )
}

export default DashCoop;